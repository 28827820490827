import "./styles.less";

import { Suspense, useContext, useEffect, useMemo, useState } from "react";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { dataLatam2021 } from "../BannerLatamInvierte/web";
import { useRouter } from "next/router";
import { monthLongName, weekdayLongName } from "@Utils/dateFormats";
import React from "react";

export const BannerLatamInvierteFlotante = () => {
	const [show, setShow] = useState(false);
	const [country, setCountry] = useState<"UY" | "PY" | "PDE">("UY");

	const { pathname } = useRouter();
	const { country_code } = useContext(ConfigStateContext);
	const data = {
		titulo: "Uruguay Invierte",
		dias: "5 y 6",
		backgroundColor1: "#ff90bc",
		backgroundColor2: "#f94785",
		backgroundColor: "#05ddcf",
		bono: country_code == "PY" ? "1.000" : "3.000",
	};

	useEffect(() => {
		if (country_code == "UY" || country_code == "PY") {
			const hoy = new Date();
			let country_selected: "UY" | "PY" | "PDE" = "PDE";
			if (country_code == "PY") country_selected = "PY";
			else if (hoy < new Date(dataLatam2021["UY"].to)) country_selected = "UY";

			const desde = new Date(dataLatam2021[country_selected].from);
			const hasta = new Date(dataLatam2021[country_selected].to);

			if (hoy.getTime() < hasta.getTime() && hoy.getTime() > desde.getTime()) {
				setCountry(country_selected);
				setShow(true);
			}
		}
	}, []);

	const getData = () => dataLatam2021[country];
	const { link, dia, hora, donde_short, to } = useMemo(getData, [country]);

	if (!show) return null;

	return (
		<div className={`banner-latam-flotante-container ${pathname.replace("/", "")}`}>
			<a
				className={"banner-latam-flotante"}
				href={link}
				target="_blank"
				style={{
					background: `linear-gradient(170deg,${data.backgroundColor2},${data.backgroundColor1})`,
				}}>
				<div className="inner" style={{ backgroundColor: data.backgroundColor }}>
					<div className="date">
						{data.dias ? null : (
							<span className="weekday">{weekdayLongName(to)}</span>
						)}
						<span className="day">
							{data.dias ? data.dias : new Date(to).getDate()}
						</span>
						<span className="month">{monthLongName(to)}</span>
					</div>
					<div className="content">
						<span className="title">{data.titulo}</span>
						<div className="down">
							<span>
								<Suspense fallback={<div>...</div>}>
									{React.createElement(React.lazy((async () => (await import("@ant-design/icons/ClockCircleOutlined")))))}
								</Suspense>
								{hora}
							</span>
							<span>
								<Suspense fallback={<div>...</div>}>
									{React.createElement(React.lazy((async () => (await import("@ant-design/icons/EnvironmentOutlined")))))}
								</Suspense>
								{donde_short}
							</span>
						</div>
					</div>
					<div
						className="btn"
						style={{
							background: `linear-gradient(170deg,${data.backgroundColor2} 10%,${data.backgroundColor1})`,
						}}>
						<span>Ver más</span>
						<Suspense fallback={<div>...</div>}>
							{React.createElement(React.lazy((async () => (await import("@ant-design/icons/RightOutlined")))))}
						</Suspense>
					</div>
				</div>
			</a>
		</div>
	);
};
