import { gql } from "@apollo/client";
import { useFilters } from "../../../Components/Filters/Filters.hook";
import { useQuery } from "@apollo/client";

export const QUERY_DESTACADO_HOME = gql`
	query DestacadoHome($operation_type_id: Int, $displayeds: [Int]) {
		featuredBannerHome(operationType: $operation_type_id, displayeds: $displayeds) {
			id
			name
			image
			url
			html_logo
			logo
			logo_text_color
			logo_bg_color
			logo_mobile_width
			clearLocalStorage
		}
	}
`;

const useBannerHome = props => {
	props = { ...props };
	const {
		filters: { operation_type_id },
	} = useFilters();
	const homeBannerIDs = typeof window !== "undefined" ? JSON.parse(localStorage.getItem("featuredBannerHomeIDs")) || [] : [];

	const { data, loading, error } = useQuery(QUERY_DESTACADO_HOME, {
		variables: {
			operation_type_id: operation_type_id, displayeds: homeBannerIDs
		},
		ssr: false
	});

	if (data && data.featuredBannerHome && typeof window !== "undefined") {
		if (data.featuredBannerHome.clearLocalStorage == true) {
			localStorage.removeItem("featuredBannerHomeIDs");
		} else {
			localStorage.setItem('featuredBannerHomeIDs', JSON.stringify([...homeBannerIDs, data.featuredBannerHome.id]))
		}
	}
	return {
		loading: loading,
		data: data?.featuredBannerHome,
		error: error,
	};
};

export { useBannerHome };
