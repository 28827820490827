import "./styles.less";

import { Button } from "antd";
import React, { useContext, useEffect, useState } from "react";

import { BannerFeaturedAgents } from "./BannerFeaturedAgents/web";
import { BannerHome } from "./BannerHome/web";
import { BannerLatamInvierteFlotante } from "shared-components/Components/Banners/BannerLatamInvierteFlotante/web";
import { BannerProptechFlotante } from "shared-components/Components/Banners/BannerProptechFlotante/web";
import { BannerSubHome } from "./BannerSubHome/web";
import { BrandInfo } from "./BrandInfo/web";
import { ConfigStateContext } from "../../Contexts/Configurations/context";
import { FeaturedSearch } from "./FeaturedSearch/web";
import { Footer } from "../Footer/web";
import { H1 } from "../../Components/SEO/H1/web";
import { HomeFilters } from "./HomeFilters/web";
import { NewsSection } from "./NewsSection/web";
import { PicHomeFooter } from "../Footer/PicHomeFooter/web";
import { GuidnesSection } from "./GuidnesSection/web";
import { AppBanner } from "./AppBanner/AppBanner";
import Head from "next/head";
import BannerAppCampaign from "shared-components/Components/Banners/BannerAppCampaign/web";
import Header from "RefactorSearchPage/Header/web";
import { useRouter } from "next/router";
import { useUser } from "shared-components/Components/User/User.hook";
import Cookies from "js-cookie";
import { openAuthModal } from "shared-components/Components/User/AuthModal/openAuthModal";
import getConfig from 'next/config'


export const HomeLayout = () => {
	const { country_code } = useContext(ConfigStateContext);
	const router = useRouter()
	const {logout} = useUser()
	const { loginBasePath } = getConfig().publicRuntimeConfig;

	const deleteHistoryAutentication = () => {
		Cookies.remove("frontend_token", { domain: loginBasePath, path: "/" });
		Cookies.remove("user_info", { domain: loginBasePath, path: "/" });
		localStorage.removeItem("user_md5");
		localStorage.removeItem("one_tap_logged");
	}

	useEffect(()=>{
		if(router.query.logout==='true' && localStorage.getItem("user_md5")!==null){
			logout.send()
			deleteHistoryAutentication()
			const url = new URL(window.location.href);
			url.searchParams.delete("logout");
			window.history.replaceState({}, "", url.toString());
		}
		else if(router.query.login==='true' && localStorage.getItem("user_md5")===null){
			deleteHistoryAutentication()
			openAuthModal({ defaultModalType: "LogIn", isFromCta: true })
		}
	},[router.query])

	return (
		<>
			<HomeStructDataJson />
			<div className={`home-page-container ${country_code}`}>
				<Header />

				{/* Main */}
				<div
					id={"home-container"}
					className={`home-container home-container-${country_code} `}>
					<HomeCover country_code={country_code} />

					{/* Banner SubHome */}
					{country_code != "BR" && <BannerSubHome />}

					{/* BrandInfo */}
					{country_code !== "ce3" && <BrandInfo />}
					{/* Banner Move-to-App campaign */}
					{country_code != "BR" && country_code != "ce3" && <BannerAppCampaign />}

					{/* FeaturedSearch */}
					<div className="featured-search-container">
						<FeaturedSearch />
					</div>

					{/* CEEE Sections */}
					{country_code === "ce3" && (
						<>
							<GuidnesSection type={"Balnearios"} />
							<GuidnesSection type={"Paseos"} />
						</>
					)}

					{/* NewsSection */}
					{(country_code == "UY" ||
						country_code == "PY" ||
						country_code == "BR" ||
						country_code == "ce3" ||
						country_code == "PE") && (
							<div className="container news-section-container">
								<h2 className="ta-center heading heading-2 high">
									<span className="section-title">{"Noticias"}</span>
								</h2>
								<NewsSection />
							</div>
						)}

					{/* App Banner */}
					{country_code === "ce3" && <AppBanner />}
				</div>

				<Footer size={"large"} isHome/>
				<PicHomeFooter />
			</div>
		</>
	);
};

const HomeCover = ({ country_code }: { country_code: string }) => {
	const [showClose, setShowClose] = useState(true);

	return (
		<section className="home-first-section">
			<div className={`home-cover ${country_code == "BR" && "hc-light"}`}>
				<BannerHome />

				<div className="home-cover_content">
					<H1 />
					<HomeFilters />
					<BannerProptechFlotante />
					<BannerLatamInvierteFlotante />
				</div>

				{country_code === "ce3" && (
					<div className={`foquita-container ${showClose ? "show" : "not-show"}`}>
						<Button className="close" onClick={() => setShowClose(false)}>
							x
						</Button>
						<img src="https://cdn2.infocasas.com.uy/web/62ec1ee84c645_foquitahome.png" />
					</div>
				)}
			</div>

			{country_code != "ce3" && (
				<div className="container ">
					<div className="agentsBannerWrapper">
						<BannerFeaturedAgents type="inmo" />
					</div>
				</div>
			)}
		</section>
	);
};

const HomeStructDataJson = () => {
	const { main_domain, socialMediaLinks, site_name } = useContext(ConfigStateContext);

	const jsonData = {
		"@context": "https://schema.org",
		"@type": "Organization",
		name: site_name,
		url: `https://${main_domain}`,
		sameAs: socialMediaLinks?.map(i => i.url),
	};

	return (
		<Head>
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: JSON.stringify(jsonData),
				}}></script>
		</Head>
	);
};

