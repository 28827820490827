import "./styles.less";

import { Col, Row, Skeleton, Typography } from "antd";

import { News } from "./News/web";
import { useNewsSection } from "./NewsSection.hook";
import { useTheme } from "../../../Styles/ThemeHook";

const { Title } = Typography;

export function NewsSection() {
	const { data, loading, error } = useNewsSection({ first: 4, home: true });

	const { theme } = useTheme();

	if (loading) return <NewSectionsSkeleton />;
	if (error) return <div>{"error"}</div>;
	if (data && data?.data?.length < 4) return null;

	const BigNews = data?.data[0];
	const Small = data?.data.slice(1, 4);

	return (
		<div className="news-section">
			<Row gutter={theme.spacing.xxlSpacing}>
				<Col xs={24} md={14}>
					<News news={BigNews} size={"large"} key={"newsLarge_" + BigNews.id} />
				</Col>
				<Col xs={24} md={10}>
					<div className="recommended">
						<Title className="title" level={3} type="secondary">
							{"Recomendado"}
						</Title>
					</div>
					{Small.map(news => (
						<News news={news} key={"newsSmall_" + news.id} />
					))}
				</Col>
			</Row>
		</div>
	);
}

const NewSectionsSkeleton = () => {
	const { theme } = useTheme();

	return (
		<Row gutter={theme.spacing.xxlSpacing}>
			<Col xs={24} md={14}>
				<Row>
					<Col span={24}>
						<Skeleton.Image className={"image-new-large-skeleton"} />
					</Col>
					<Col span={24}>
						<Skeleton active />
					</Col>
				</Row>
			</Col>
			<Col xs={24} md={10}>
				{[1, 2, 3].map(news => (
					<Row key={news}>
						<Col span={12}>
							<Skeleton active />
						</Col>
						<Col span={12}>
							<Skeleton.Image className={"image-new-small-skeleton"} />
						</Col>
					</Row>
				))}
			</Col>
		</Row>
	);
};
