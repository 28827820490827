import "./styles.less";

import { CloseOutlined } from "@ant-design/icons";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { ImagenOptimizada as Img } from "shared-components/Components/Image/_new_web";
import { useContext } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useRouter } from "next/router";
import { useState } from "react";

const data = {
	PY: {
		from: "Nov 23, 2021 16:00:00",
		to: "Dec 4, 2021 20:00:00",
		link:
			"https://www.infocasas.com.py/latam-invierte-py/amp3?utm_source=portal&utm_medium=header_latam&utm_campaign=latam_py_2021",
		key_ls: "banner-latam-invierte-py-2021",
		dia: "4 de DICIEMBRE",
		hora: "9 a 20hs",
		donde: "Hotel Sheraton, Asunción",
		donde_short: "Hotel Sheraton",
	},
	PDE: {
		from: "Nov 27, 2021 17:00:00",
		to: "Dec 4, 2021 19:00:00",
		link:
			"https://www.infocasas.com.uy/latam-invierte-uy/amp2?utm_source=portal&utm_medium=header_latam&utm_campaign=latam_pde_2021",
		key_ls: "banner-latam-invierte-pde-2021",
		dia: "4 de DICIEMBRE",
		hora: "10 a 20hs",
		donde: "Enjoy Punta del Este",
		donde_short: "Enjoy Punta del Este",
	},
	UY: {
		from: "Dec 17, 2021 12:00:00",
		to: "Jan 6, 2022 22:00:00",
		link:
			"https://www.infocasas.com.uy/uruguay-invierte/amp?utm_source=portal&utm_medium=header_uruguay_invierte&utm_campaign=uruguay_invierte_2022",
		key_ls: "banner-uruguay-invierte-2022",
		dia: "5 y 6 de Enero",
		hora: "16 - 22 hs",
		donde: "Hotel Enjoy Punta del Este",
		donde_short: "Enjoy Punta del Este",
	},
};

export { data as dataLatam2021 };

const BannerLatamInvierte = () => {
	const [country, setCountry] = useState<"UY" | "PY" | "PDE">("UY");
	const [show, setShow] = useState(false);

	const { country_code } = useContext(ConfigStateContext);
	const { pathname } = useRouter();

	const hideBanner = e => {
		e.preventDefault();
		document.body.classList.remove("body-banner-latam-invierte");
		localStorage.setItem(data[country].key_ls, String(new Date().getTime()));
		setShow(false);
	};
	const showBanner = () => {
		document.body.classList.add("body-banner-latam-invierte");
		setShow(true);
	};

	useEffect(() => {
		const hoy = new Date();

		let country_selected: "UY" | "PY" | "PDE" = "PDE";
		if (country_code == "PY") country_selected = "PY";
		else if (hoy < new Date(data["UY"].to)) country_selected = "UY";

		const desde = new Date(data[country_selected].from);
		const hasta = new Date(data[country_selected].to);
		if (
			(country_code == "UY" || country_code == "PY") &&
			(pathname.includes("searchPage") ||
				pathname.includes("home") ||
				pathname.includes("propSingle") ||
				pathname.includes("projectSingle")) &&
			hoy.getTime() < hasta.getTime() &&
			hoy.getTime() > desde.getTime()
		) {
			const LAST_CLOSED = localStorage.getItem(data[country_selected].key_ls);
			if (LAST_CLOSED == null || Number(LAST_CLOSED) < hoy.getTime() - 24 * 60 * 60 * 1000) {
				setCountry(country_selected);
				showBanner();
			}
		}
	}, []);

	const getData = () => data[country];
	const { link, dia, hora, donde } = useMemo(getData, [country]);

	if (!show) return null;

	return (
		<a id="banner-latam-invierte" href={link} target="_blank">
			<CloseOutlined onClick={hideBanner} className="close-icon" />
			<div className="container">
				<div className="gif">
					<Img src="https://cdn2.infocasas.com.uy/web/61c32a84cd790_infocdn__header.gif" />
				</div>
				<div className="right">
					<div className="middle">
						<p>
							<b>
								{dia} ・ {hora}
							</b>
							<br />
							{donde}
						</p>
					</div>
					<div className="btn">Quiero Asistir</div>
				</div>
			</div>
		</a>
	);
};

export { BannerLatamInvierte };
