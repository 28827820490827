import "./styles.less";

import React, { useEffect, useState } from "react";

import { BannerSubHomeItem } from "./BannerSubHomeItem/web";
import { Carousel } from "antd";
import { useBannerSubHome } from "./BannerSubHome.hook";
import { useTheme } from "../../../Styles/ThemeHook";

export const BannerSubHome = () => {
	const { theme } = useTheme();
	const { data, loading, error } = useBannerSubHome({ limit: 3 });

	const [settings, setSettings] = useState({
		render: true,
		arrows: false,
		responsive: [],
		infinite: true,
		speed: 400,
		autoplaySpeed: 6500,
		autoplay: false,
		slidesToShow: 3,
		slidesToScroll: 1,
	});

	useEffect(() => {
		setSettings({
			...settings,
			render: false,
			responsive: [
				{
					breakpoint: Number(
						theme.breakPoints.md.substring(0, theme.breakPoints.md.length - 2)
					),
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						dots: true,
						autoplay: true,
						autoplaySpeed: 6800,
					},
				},
				{
					breakpoint: Number(
						theme.breakPoints.sm.substring(0, theme.breakPoints.sm.length - 2)
					),
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						dots: true,
						autoplay: true,
						autoplaySpeed: 6800,
					},
				},
				{
					breakpoint: Number(
						theme.breakPoints.xs.substring(0, theme.breakPoints.xs.length - 2)
					),
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						dots: true,
						autoplay: true,
						autoplaySpeed: 6800,
					},
				},
			],
		});
	}, []);

	useEffect(() => {
		if (!settings.render) setSettings({ ...settings, render: true });
	}, [settings.render]);

	if (!settings.render) return null;
	if (error) return null;
	if (data && data.length < 3) return null;

	const banners = loading ? [...Array(3)] : data;

	return (
		<div className="container banner-sub-home">
			<h2 className="ta-center heading heading-2 high">
				Destacados
			</h2>

			<Carousel dots={{ className: "banner-sub-home-dots" }} {...settings}>
				{banners.map((banner, i) => (
					<BannerSubHomeItem
						key={"banner_subhome_" + i}
						image={banner?.image}
						link={banner?.url}
						position={i + 1}
						loading={loading}
					/>
				))}
			</Carousel>
		</div>
	);
};
