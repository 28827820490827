import Icon from "@ant-design/icons";

const LeftArrowSvg = () => (
	<svg
		width="0.6em"
		height="1em"
		viewBox="0 0 11 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.853 9.052L11 16.107L9.132 18L0.0640019 9.049L9.133 0.122L10.999 2.018L3.853 9.052Z"
			fill="currentColor"
		/>
	</svg>
);

export const LeftArrowIcon = props => <Icon component={LeftArrowSvg} {...props} />;
