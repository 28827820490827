import "./styles.less";

import { Button, Skeleton, Typography } from "antd";
import { ImagenOptimizada as Img } from "../../../../Components/Image/web";
import { RightOutlined } from "@ant-design/icons";
import { removeUrlProtocol } from "../../../../Utils/Functions";
// import { useGoogleAnalytics } from "shared-components/GlobalHooks/web/GoogleAnalytics.hook";

const { Text, Link } = Typography;

export const FeaturedSearchItem = ({ item, loading }) => {
	// const GA = useGoogleAnalytics();

	const splitText = item.featured.title.split(" ");
	const operationText = splitText.shift();
	const restText = splitText.join(" ");

	const clicked = () => {
		// GA.Event({
		// 	category: 'user_event',
		// 	action: 'home_featured_search',
		// 	label: `${operationText} - ${restText}`
		// })
	}

	return (
		<Link onClick={clicked} href={loading ? null : removeUrlProtocol(item.featured.url)} target="_blank" rel="">
			<div className={"item"}>
				{loading ? (
					<Skeleton.Image />
				) : (
					<>
						<Img src={item.featured.image} alt={item.featured.title} />
						<div className="overLay" />
						<Text className="operation-text">{operationText}</Text>
						<Text className="text">{restText}</Text>
						<Button className="more" type="text">
							{"Ver más"}
							<RightOutlined />
						</Button>
					</>
				)}
			</div>
		</Link>
	);
};
