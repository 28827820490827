import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const NOTICIAS_QUERY = gql`
	query Noticias($first: Int!, $home: Boolean!) {
		blogPosts(first: $first, home: $home) {
			data {
				id
				title
				slug
				description
				created
				image
				tags {
					slug
					url
				}
			}
		}
	}
`;

export interface NewsProps {
	first: number;
	home: boolean;
}

const useNewsSection = ({ first, home }: NewsProps) => {
	const { data, loading, error } = useQuery(NOTICIAS_QUERY, {
		variables: { first: first, home: home },
	});

	return {
		loading: loading,
		data: data?.blogPosts,
		error: error,
	};
};

export { useNewsSection };
