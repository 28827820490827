import { useContext } from "react"
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context"

export const useBranInfo = () => {
	const { country_code } = useContext(ConfigStateContext)

	const DATA_BRAND_INFO_URUGUAY = [
		{
			title: "Todas las propiedades",
			paragraph:
				"Con más de 200.000 propiedades publicadas, alcanzamos el 97% de la oferta inmobiliaria del país.",
			imgUrl: "https://cdn2.infocasas.com.uy/web/5f74d7f0f2408_infocdn__propiedades.png",
			country: "UY",
		},
		{
			title: "Búsqueda exacta",
			paragraph:
				"Nuestros filtros te permiten encontrar la propiedad que estás buscando en el menor tiempo posible.",
			imgUrl: "https://cdn2.infocasas.com.uy/web/5f74d700efa10_infocdn__busqueda.png",
			country: "UY",
		},
		{
			title: "Big Data",
			paragraph:
				"Diseñamos herramientas para que puedas tomar las mejores decisiones basadas en datos.",
			imgUrl: "https://cdn2.infocasas.com.uy/web/5f74d6c6b18c4_infocdn__big-data.png",
			country: "UY",
		},
	]

	const DATA_BRAND_INFO_BOLIVIA = [
		{
			title: "Todas las propiedades",
			paragraph:
				"Con más de 20.000 propiedades publicadas, alcanzamos el 97% de la oferta inmobiliaria del país.",
			imgUrl: "https://cdn2.infocasas.com.uy/web/5f74d7f0f2408_infocdn__propiedades.png",
			country: "BO",
		},
		{
			title: "Búsqueda exacta",
			paragraph:
				"Nuestros filtros te permiten encontrar la propiedad que estás buscando en el menor tiempo posible.",
			imgUrl: "https://cdn2.infocasas.com.uy/web/5f74d700efa10_infocdn__busqueda.png",
			country: "BO",
		},
		{
			title: "Big Data",
			paragraph:
				"Diseñamos herramientas para que puedas tomar las mejores decisiones basadas en datos.",
			imgUrl: "https://cdn2.infocasas.com.uy/web/5f74d6c6b18c4_infocdn__big-data.png",
			country: "BO",
		},
	]

	const DATA_BRAND_INFO_PARAGUAY = [
		{
			title: "Todas las propiedades",
			paragraph:
				"Con más de 60.000 propiedades publicadas, alcanzamos el 97% de la oferta inmobiliaria del país.",
			imgUrl: "https://cdn2.infocasas.com.uy/web/5f74d7f0f2408_infocdn__propiedades.png",
			country: "PY",
		},
		{
			title: "Búsqueda exacta",
			paragraph:
				"Nuestros filtros te permiten encontrar la propiedad que estás buscando en el menor tiempo posible.",
			imgUrl: "https://cdn2.infocasas.com.uy/web/5f74d700efa10_infocdn__busqueda.png",
			country: "PY",
		},
		{
			title: "Big Data",
			paragraph:
				"Diseñamos herramientas para que puedas tomar las mejores decisiones basadas en datos.",
			imgUrl: "https://cdn2.infocasas.com.uy/web/5f74d6c6b18c4_infocdn__big-data.png",
			country: "PY",
		},
	]

	const DATA_BRAND_INFO_BRASIL = [
		{
			title: "Todos os imóveis",
			paragraph: "Encontre a mais ampla oferta de novas construções em São Paulo.",
			imgUrl: "https://d3m49n4oy99qs7.cloudfront.net/web/600ae2c4c5339_infocdn__1.png",
			country: "BR",
		},
		{
			title: "Encontrará o que procura",
			paragraph:
				"Filtros únicos que permitem encontrar o imóvel que procura no menor tempo possível.",
			imgUrl: "https://d3m49n4oy99qs7.cloudfront.net/web/600ae2c4c60a6_infocdn__2.png",
			country: "BR",
		},
		{
			title: "Big data ao seu alcance",
			paragraph: "Tome as melhores decisões com base em dados.",
			imgUrl: "https://d3m49n4oy99qs7.cloudfront.net/web/600ae2c4c5120_infocdn__3.png",
			country: "BR",
		},
	]

	const DATA_BRAND_INFO_FINCA = [
		{
			title: "Todas las propiedades",
			paragraph: "Con más de 200.000 propiedades publicadas, alcanzamos el 97% de la oferta inmobiliaria del país.",
			imgUrl: "https://cdn1.infocasas.com.uy/web/6585c7f22214b_finca-todas-las-propiedades.png",
			country: "CO",
		},
		{
			title: "Búsqueda exacta",
			paragraph:
				"Nuestros filtros te permiten encontrar la propiedad que estás buscando en el menor tiempo posible.",
			imgUrl: "https://cdn1.infocasas.com.uy/web/6585c7f1eddc9_finca-busqueda-exacta.png",
			country: "CO",
		},
		{
			title: "Big Data",
			paragraph: "Diseñamos herramientas para que puedas tomar las mejores decisiones basadas en datos.",
			imgUrl: "https://cdn1.infocasas.com.uy/web/6585c7f2224fb_finca-big-data.png",
			country: "CO",
		},
	]

	return {
		data: [
			...DATA_BRAND_INFO_URUGUAY,
			...DATA_BRAND_INFO_BOLIVIA,
			...DATA_BRAND_INFO_PARAGUAY,
			...DATA_BRAND_INFO_BRASIL,
			...DATA_BRAND_INFO_FINCA
		].filter(item => item.country === country_code),
	}
}
