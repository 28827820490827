import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { useFilters } from "../../../Components/Filters/Filters.hook";

const DESTACADO_SUBHOME_QUERY = gql`
	query SubBanners($limit: Int, $operationType: Int, $displayeds: [Int]) {
		featuredSubBannersHome(limit: $limit, operationType: $operationType, displayeds: $displayeds) {
			id
			url
			image
			name
			operation_type_id
			clearLocalStorage
		}
	}
`;

const DEFAULT_PROPS = {
	fetchPolicy: "cache-first",
	limit: 3,
};

const useBannerSubHome = props => {
	props = { ...DEFAULT_PROPS, ...props };
	const subHomeBannerIDs = typeof window !== "undefined" ? JSON.parse(localStorage.getItem("featuredBannerSubHomeIDs")) || [] : [];

	const {
		filters: { operation_type_id },
	} = useFilters();

	const { data, loading, error } = useQuery(DESTACADO_SUBHOME_QUERY, {
		variables: {
			limit: props.limit,
			operationType: operation_type_id,
			displayeds: subHomeBannerIDs,
		},
		ssr: false
	});

	if (data && data.featuredSubBannersHome && typeof window !== "undefined") {
		let ids = []
		data.featuredSubBannersHome.forEach(banner => {
			if (banner.clearLocalStorage == true) {
				localStorage.removeItem("featuredBannerSubHomeIDs");
			} else {
				ids.push(banner.id)
			}
		});
		if (ids.length) {
			localStorage.setItem('featuredBannerSubHomeIDs', JSON.stringify([...subHomeBannerIDs, ...ids]))
		}
	}

	return {
		loading: loading,
		data: data?.featuredSubBannersHome,
		error: error,
	};
};

export { useBannerSubHome };
