import { useQuery,gql } from "@apollo/client";

const QUERY_RECOMENDADAS = gql`
  query FeaturedSearches {
    featuredSearches {
      title
      url
      image
    }
  }
`;

const useFeaturedSearch = () => {

    const { data, loading, error } = useQuery(QUERY_RECOMENDADAS, {fetchPolicy:"cache-first"});
    return {loading, data, error}
};


export { useFeaturedSearch };
