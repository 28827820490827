
export const monthShortName = (date) => {
    const d = new Date(date);
    return d.toLocaleDateString('es-ES', {month:'short'})
}

export const monthLongName = (date) => {
    const d = new Date(date);
    return d.toLocaleDateString('es-ES', {month:'long'})
}


export const weekdayLongName = (date) => {
    const d = new Date(date);
    return d.toLocaleDateString('es-ES', {weekday:'long'})
}

export const weekdayShortName = (date) => {
    const d = new Date(date);
    return d.toLocaleDateString('es-ES', {weekday:'short'})
}