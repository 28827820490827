import "./styles.less";

import { ClockCircleOutlined, EnvironmentOutlined, RightOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";

import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { monthLongName, weekdayLongName } from "@Utils/dateFormats";

export const BannerProptechFlotante = () => {
	const dataCongreso = {
		link: "https://www.infocasas.com.uy/eventos/encuentra-proptech-congreso/amp",
		date: dayjs("05-11-2021", "DD-MM-YYYY"),
		hours: "9-18:30 hs",
		location: "Kibón Avanza",
		titulo: "Congreso Proptech",
		dias: null,
		backgroundColor1: "#4630f6",
		backgroundColor2: "#21cec5",
		utm: "congreso",
	};
	const dataSalon = {
		link: "https://www.infocasas.com.uy/eventos/encuentra-proptech/amp6",
		date: dayjs("06-11-2021", "DD-MM-YYYY"),
		dias: "5 al 7",
		hours: "9-18:30 hs",
		location: "Kibón Avanza",
		titulo: "Salón inmobiliario",
		backgroundColor1: "#4630f6",
		backgroundColor2: "#21cec5",
		utm: "saloninmobiliario",
	};

	const dataJornada = {
		link: "https://infocasas.com.uy/eventos/proptech-jornada-solidaria",
		date: dayjs("07-11-2021", "DD-MM-YYYY"),
		hours: "11-18 hs",
		location: "Kibón Avanza",
		titulo: "Jornada Solidaria",
		backgroundColor1: "#4630f6",
		backgroundColor2: "#21cec5",
		dias: null,
		utm: "jornadasolidaria",
	};

	const [data, setData] = useState(null);
	const { pathname, query } = useRouter();
	const { country_code } = useContext(ConfigStateContext);

	useEffect(() => {
		if (country_code == "UY") {
			let now = dayjs();
			const until = dayjs("07-11-2021", "DD-MM-YYYY");
			if (typeof query.proptechcongreso != "undefined") {
				setData(dataCongreso);
			} else if (typeof query.proptechsalon != "undefined") {
				setData(dataSalon);
			} else if (typeof query.proptechjornada != "undefined") {
				setData(dataJornada);
			} else if (now.isSameOrBefore(until, "date")) {
				let banner;
				if (now.month() == 9) {
					if (now.date() % 3 == 1) banner = dataSalon;
					else if (now.date() % 3 == 2) banner = dataJornada;
					else banner = dataCongreso;
				} else {
					if (now.date() % 3 == 1) banner = dataJornada;
					else if (now.date() % 3 == 2) banner = dataCongreso;
					else banner = dataSalon;
				}
				setData(banner);
			}
		}
	}, []);

	if (data == null) return null;

	return (
		<div className={`banner-proptech-flotante-container ${pathname.replace("/", "")}`}>
			<a
				className={"banner-proptech-flotante"}
				href={
					data.link +
					"?utm_source=web&utm_medium=banner_flotante&utm_campaign=proptech&utm_content=" +
					data.utm
				}
				target="_blank"
				style={{
					background: `linear-gradient(170deg,${data.backgroundColor1},${data.backgroundColor2})`,
				}}>
				<div className="inner" style={{ backgroundColor: data.backgroundColor2 }}>
					<div className="date">
						{data.dias ? null : (<>
							<span className="weekday">{weekdayLongName(data.date)}</span>
							</>)}
						<span className="day">{data.dias ? data.dias : data.date.date()}</span>
						<span className="month">{monthLongName(data.date)}</span>
					</div>
					<div className="content">
						<span className="title">{data.titulo}</span>
						<div className="down">
							<span>
								<ClockCircleOutlined />
								{data.hours}
							</span>
							<span>
								<EnvironmentOutlined />
								{data.location}
							</span>
						</div>
					</div>
					<div className="btn">
						<span>Ver más</span>
						<RightOutlined />
					</div>
				</div>
			</a>
		</div>
	);
};
