import { Typography, List, Row, Col } from "antd";
import { ImagenOptimizada as Img } from "../../../Components/Image/web";
import "./style.less";
import { LocationIcon } from "shared-components/Components/CustomIcons/LocationIcon/web";

const { Title, Text } = Typography;

export const GuidnesSection = ({ type }: { type: "Balnearios" | "Paseos" }) => {
    const subtitle = type === "Balnearios" ? "Te dejamos una guía con los balnearios más visitados" : "Lee un poco más sobre los lugares que vas a visitar"

    const dataBalnearios = [
        {
            title: "Rocha",
            image: "https://cdn2.casaseneleste.com/web/598e18d1bf9b6_infocdn__598c4e0767ba1_infocdn__balnearios-rocha-optimizada.jpg",
            list: [
                {
                    title: "Punta del Diablo",
                    url: "https://www.casaseneleste.com/blog/destinos/punta-del-diablo.html"
                },
                {
                    title: "La Paloma",
                    url: "https://www.casaseneleste.com/blog/destinos/la-paloma.html"
                },
                {
                    title: "La Pedrera",
                    url: "https://www.casaseneleste.com/blog/destinos/la-pedrera.html"
                }
            ]
        },
        {
            title: "Maldonado",
            image: "https://cdn2.casaseneleste.com/web/598e18eb9c760_infocdn__598c4e8ba32b4_infocdn__balnearios-maldonado-optimi.jpg",
            list: [
                {
                    title: "Punta del Este",
                    url: "https://www.casaseneleste.com/blog/destinos/punta-del-este.html"
                },
                {
                    title: "Punta Ballena",
                    url: "https://www.casaseneleste.com/blog/destinos/punta-ballena.html"
                },
                {
                    title: "Piriápolis",
                    url: "https://www.casaseneleste.com/blog/destinos/piriapolis.html"
                }
            ]
        },
        {
            title: "Canelones",
            image: "https://cdn2.casaseneleste.com/web/598e19080b78d_infocdn__598c4ef91aaf8_infocdn__balnearios-canelones-optimi.jpg",
            list: [
                {
                    title: "Parque del Plata",
                    url: "https://www.casaseneleste.com/blog/destinos/parque-del-plata.html"
                },
                {
                    title: "Atlántida",
                    url: "https://www.casaseneleste.com/blog/destinos/atlantida.html"
                },
                {
                    title: "La Floresta",
                    url: "https://www.casaseneleste.com/blog/destinos/la-floresta.html"
                }
            ]
        }
    ];

    const dataPaseos = [
        {
            title: "Rocha",
            image: "https://cdn2.casaseneleste.com/web/598e193dbb6bb_infocdn__598c4f4261139_infocdn__paseos-rocha-optimizada.jpg",
            list: [
                {
                    title: "Laguna Garzón",
                    url: "https://www.casaseneleste.com/blog/paseos/laguna-garzon.html"
                },
                {
                    title: "Faro Cabo Polonio",
                    url: "https://www.casaseneleste.com/blog/paseos/faro-cabo-polonio.html"
                },
                {
                    title: "Sierras de Rocha",
                    url: "https://www.casaseneleste.com/blog/paseos/sierras-de-rocha.html"
                }
            ]
        },
        {
            title: "Maldonado",
            image: "https://cdn2.casaseneleste.com/web/598e196076d57_infocdn__598c4f6e2ac04_infocdn__paseos-pitamiglio-optimizad.jpg",
            list: [
                {
                    title: "Isla Gorriti",
                    url: "https://www.casaseneleste.com/blog/paseos/isla-gorriti.html"
                },
                {
                    title: "Casapueblo",
                    url: "https://www.casaseneleste.com/blog/paseos/casapueblo.html"
                },
                {
                    title: "Castillo Pittamiglio",
                    url: "https://www.casaseneleste.com/blog/paseos/castillo-pittamiglio.html"
                }
            ]
        },
        {
            title: "Canelones",
            image: "https://cdn2.casaseneleste.com/web/598e197da20d1_infocdn__598c4f8d816cc_infocdn__paseos-canelones-optimizada.jpg",
            list: [
                {
                    title: "Parroquia Cristo Obrero",
                    url: "https://www.casaseneleste.com/blog/paseos/parroquia-cristo-obrero.html"
                },
                {
                    title: "El Águila",
                    url: "https://www.casaseneleste.com/blog/paseos/el-aguila.html"
                },
            ]
        }
    ];

    const actualData = type === "Balnearios" ? dataBalnearios : dataPaseos

    return (
        <Row>
            <Col span={24}>
                <Title className="ta-center" level={2}>
                    <span className="section-title">{type}</span>
                </Title>
                <Title className="ta-center" level={5}>{subtitle}</Title>

                <Row gutter={[24, 16]} className="guidnes-container">
                    {
                        actualData.map((data, index) => (
                            <Col sm={24} md={12} lg={8} key={`guidnes-${index}`}>
                                <div className="header-container">
                                    <div className="item">
                                        <Img src={data.image} alt={data.title} width={200} height={200} />
                                    </div>
                                    <h2 className="title">{data.title}</h2>
                                </div>


                                <List
                                    dataSource={data.list}
                                    renderItem={item => (
                                        <List.Item>
                                            <a href={item.url} className="item-list">
                                                <LocationIcon width={16} />
                                                <Text strong>{item.title}</Text>
                                            </a>
                                        </List.Item>
                                    )}
                                ></List>
                            </Col>
                        ))
                    }
                </Row>
            </Col>
        </Row>
    )
}