import { Button, Skeleton, Typography } from "antd";

import { ImagenOptimizada as Img } from "../../../../Components/Image/web";
import React, { useContext } from "react";
import { firstLetterToUppercase } from "src/utils/Functions";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";

const { Link } = Typography;
let relValue = "";
export const BannerSubHomeItem = ({ image, link, position, key, loading }) => {
	const { translations } = useContext(ConfigStateContext);

	if (link) {
		if (link[0] != "/" && !link.includes("infocasas.com.")) relValue = "nofollow";
		else relValue = "";
	}
	return (
		<div
			className="destacado"
			key={key ?? ""}
			style={{ animationDelay: 400 * position + "ms" }}
		>
			{loading ? (
				<Skeleton.Image key={"banner-sub-home" + key} className="image-container" />
			) : (
				<Link className="image-container" href={link} target="_blank" rel={relValue}>
					<Img src={image} alt={image} />
					<Button className="btn-ver">
						{firstLetterToUppercase(translations.conoce)} más
					</Button>
				</Link>
			)}
		</div>
	);
};

