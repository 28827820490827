import React from "react";
import { GlobalComponents } from "./GlobalComponents";


export function withGlobalComponents(PageComponent) {
	const withGlobalComponents = ({ children, ...pageProps }) => {
		return (
			<GlobalComponents>
				<PageComponent {...pageProps}>{children}</PageComponent>
			</GlobalComponents>
		);
	};

	if (PageComponent.getInitialProps) {
		withGlobalComponents.getInitialProps = async ctx => {
			let pageProps = {};
			if (PageComponent.getInitialProps) pageProps = await PageComponent.getInitialProps(ctx);
			return { ...pageProps };
		};
	}

	return withGlobalComponents;
}
