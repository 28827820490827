import { HomeLayout } from "../shared-components/ViewFragments/Home/web";
import { RTBhouse } from "../shared-components/Components/RTBhouse/web";
import React from "react";
import { withApollo } from "../lib/apollo3";
import { withAuth } from "../lib/auth";
import { withContext } from "../lib/context";
import { withGlobalComponents } from "../shared-components/ViewFragments/GlobalComponents/web";
import { withTranslation } from "../shared-components/i18n";
import { decodeHashUrl } from "@Utils/Functions";
import AdvertiserProvider from "src/contexts/AdvertiserContext";

// Home Page
const Home = () => {
	return (
		<AdvertiserProvider>
			<HomeLayout />
			<RTBhouse page={"home"} />
		</AdvertiserProvider>
	);
};

Home.getInitialProps = async function({ req, query }) {
	const HomeFiltersInitVal = {
		operation_type_id: { text: "Venta", value: 1 },
		property_type_id: [
			{ text: "Casas", value: 1 },
			{ text: "Apartamentos", value: 2 },
		],
		season: null,
		dateFrom: null,
		dateTo: null,
	};
	const q = decodeHashUrl(query.hashed);
	return {
		FiltersContextInitialState: HomeFiltersInitVal,
		ConfigContextInitialState: q?.configs,
		CurrentUrlContextInitialState: {
			url: typeof window !== "undefined" ? window.location.href : req.url,
		},
	};
};

export default withApollo(
	withContext(withTranslation(withAuth(withGlobalComponents(Home))), {
		withDummyContext: false,
		withFiltersContext: true,
		withConfigContext: true,
		withThemeContext: true,
	})
);

