/** This component was inspired by the Show component in SolidJS
 *  https://www.solidjs.com/docs/latest/api#show
 * */
type ShowProps<T> = {
	when: T | undefined | null | false;
	children: JSX.Element;
	other?: JSX.Element;
};

const Show = <T,>({ when, children, other }: ShowProps<T>) => {
	if (when) {
		return children;
	}

	return other || null;
};

export default Show;
