import "./styles.less";

import React, { FC } from "react";

import { useTheme } from "../../../../Styles/ThemeHook";
import { RightArrowIcon } from "shared-components/Components/CustomIcons/RightArrowIcon/web";
import { LeftArrowIcon } from "shared-components/Components/CustomIcons/LeftArrowIcon/web";

interface CarouselArrowProps {
	onClick?: () => void;
	className?: string;
	side?: "right" | "left";
	position?: "inside" | "outside";
	type?: "filled" | "ghost";
	handleClick?: () => void;
}

export const CarouselArrow: FC<CarouselArrowProps> = ({
	onClick = null,
	side = "right",
	type = "ghost",
	position = "inside",
	className,
	handleClick = () => { },
	...rest
}) => {
	const { theme } = useTheme();
	if (className.indexOf("slick-disabled") != -1) return null;

	return (
		<React.Fragment>
			<div
				className={"carrouselArrow " + side}
				onClick={e => {
					e.stopPropagation();
					e.preventDefault();
					onClick();
					handleClick();
				}}>
				{side == "right" ? (
					<RightArrowIcon className="icon-arrow" />
				) : (
					<LeftArrowIcon className="icon-arrow" />
				)}
			</div>
			<style jsx>{`
				.carrouselArrow {
					color: ${type == "filled" ? theme.colors.textColorMedium : theme.colors.borderColor};
					background: ${type == "filled" ? theme.colors.backgroundColor : "transparent"};
					border: ${type == "filled" ? "1px solid " + theme.colors.borderColor : "none"};
				}
				.carrouselArrow:hover {
					background: ${type == "filled"
					? theme.colors.borderColor
					: theme.colors.textSecondaryColor};
					color: ${type == "filled"
					? theme.colors.textColorMedium
					: theme.colors.backgroundColor};
					transform: ${type == "filled" ? "scale(1.1)" : "none"};
				}
				.carrouselArrow.right {
					right: ${position == "inside"
					? theme.spacing.smSpacing
					: -theme.spacing.lgSpacing}px;
				}
				.carrouselArrow.left {
					left: ${position == "inside"
					? theme.spacing.smSpacing
					: -theme.spacing.lgSpacing}px;
				}
				.carrouselArrow :global(.icon-arrow) {
					font-size: ${type == "filled" ? 18 : 20}px;
					text-shadow: ${type == "filled" ? "none" : "0 0 3px rgba(0,0,0,0.6)"};
				}
				@media screen and (max-width: ${theme.breakPoints.lg}) {
					.carrouselArrow.right {
						right: ${position == "inside"
					? theme.spacing.smSpacing
					: -theme.spacing.mdSpacing}px;
					}
					.carrouselArrow.left {
						left: ${position == "inside"
					? theme.spacing.smSpacing
					: -theme.spacing.mdSpacing}px;
					}
				}
			`}</style>
		</React.Fragment>
	);
};

