import React from "react";
import { DummyContextProvider } from "../shared-components/Contexts/Dummy/context";
import { FiltersContextProvider } from "../shared-components/Contexts/Filters/context";
import { ConfigurationsProvider } from "../shared-components/Contexts/Configurations/context";
import { ThemeProvider } from "../shared-components/Contexts/Theme/context";
import getConfig from "next/config";
import { UserProvider } from "shared-components/Contexts/User/context";
const { APP_THEME } = getConfig().publicRuntimeConfig;

export function withContext(
	PageComponent,
	{
		withDummyContext = false,
		withThemeContext = true,
		withConfigContext = true,
		withFiltersContext = true,
		withUserContext = true,
	}: {
		withDummyContext?: boolean;
		withThemeContext?: boolean;
		withConfigContext?: boolean;
		withFiltersContext?: boolean;
		withUserContext?: boolean;
	}
) {
	const withContext = ({ ...pageProps }) => {
		return (
			<DummyContextProvider
				active={withDummyContext}
				initialState={pageProps?.DummyContextInitialState}>
				
				<UserProvider active={withUserContext} initialState={null}>

					<ConfigurationsProvider
						active={withConfigContext}
						initialState={pageProps?.ConfigContextInitialState}>
						<ThemeProvider active={withThemeContext} APP_THEME={APP_THEME}>
							<FiltersContextProvider
								active={withFiltersContext}
								initialState={pageProps?.FiltersContextInitialState}>
								<PageComponent {...pageProps} />
							</FiltersContextProvider>
						</ThemeProvider>
					</ConfigurationsProvider>

				</UserProvider>
				
			</DummyContextProvider>
		);
	};

	if (PageComponent.getInitialProps) {
		withContext.getInitialProps = async ctx => {
			let pageProps = {};
			if (PageComponent.getInitialProps) {
				pageProps = await PageComponent.getInitialProps(ctx);
			}

			return {
				...pageProps,
				//add other props if desired hola3:"q onda",
			};
		};
	}

	return withContext;
}
