import {createContext, ReactNode, useReducer} from "react";
import {Dummy} from "./model";


const DummyStateContext = createContext(null);
const DummyDispatchContext = createContext(null);

const defaultState : Dummy = {count: 11};

const reducer = (state : Dummy, action) : Dummy => {
    switch (action.type) {
        case 'increment':
            return {count: state.count + 1};
        case 'decrement':
            return {count: state.count - 1};
        default:
            throw new Error("no funciona");
    }
};

const DummyContextProvider = ({ children, initialState = null, active } : {children: ReactNode, initialState : Dummy, active: boolean}) => {
    if(!active) {
        return (<>{children}</>);
    }

    const [state, dispatch] = useReducer(reducer,initialState == null?defaultState:initialState);

    return (

        <DummyDispatchContext.Provider value={dispatch}>
            <DummyStateContext.Provider value={state}>
                {children}
            </DummyStateContext.Provider>
        </DummyDispatchContext.Provider>
    );

};

export {DummyStateContext, DummyDispatchContext, DummyContextProvider}

