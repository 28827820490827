import "./styles.less";

import { Carousel, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";

import { CarouselArrow } from "../../../Components/Property/LazyImageGallery/CarouselArrow/web";
import { FeaturedSearchItem } from "./FeaturedSearchItem/web";
import { useFeaturedSearch } from "./FeaturedSearch.hook";
import { useTheme } from "../../../Styles/ThemeHook";

const { Title } = Typography;

export const FeaturedSearch = () => {
	const { theme } = useTheme();
	const { data, loading, error } = useFeaturedSearch();

	const [settings, setSettings] = useState({
		render: true,
		arrows: false,
		dots: false,
		nextArrow: <CarouselArrow type={"filled"} side={"right"} />,
		prevArrow: <CarouselArrow type={"filled"} side={"left"} />,
		responsive: [],
		speed: 500,
		autoplaySpeed: 5500,
		autoplay: false,
		slidesToShow: 6,
	});

	useEffect(() => {
		setSettings({
			...settings,
			render: false,
			responsive: [
				{
					breakpoint: Number(
						theme.breakPoints.xxl.substring(0, theme.breakPoints.xxl.length - 2)
					),
					settings: {
						arrows: true,
						autoplay: true,
						slidesToShow: 5,
						centerPadding: "75px",
						className: "center",
						centerMode: true,
						infinite: true,
					},
				},
				{
					breakpoint: Number(
						theme.breakPoints.xl.substring(0, theme.breakPoints.xl.length - 2)
					),
					settings: {
						arrows: true,
						autoplay: true,
						slidesToShow: 4,
						centerPadding: "75px",
						className: "center",
						centerMode: true,
						infinite: true,
					},
				},
				{
					breakpoint: Number(
						theme.breakPoints.lg.substring(0, theme.breakPoints.lg.length - 2)
					),
					settings: {
						arrows: true,
						autoplay: true,
						slidesToShow: 3,
						centerPadding: "65px",
						className: "center",
						centerMode: true,
						infinite: true,
					},
				},
				{
					breakpoint: Number(
						theme.breakPoints.md.substring(0, theme.breakPoints.md.length - 2)
					),
					settings: {
						arrows: true,
						slidesToShow: 2,
						centerPadding: "55px",
						className: "center",
						centerMode: true,
						infinite: true,
					},
				},
				{
					breakpoint: Number(
						theme.breakPoints.sm.substring(0, theme.breakPoints.sm.length - 2)
					),
					settings: {
						arrows: false,
						slidesToShow: 1,
						centerPadding: "45px",
						className: "center",
						centerMode: true,
						infinite: true,
					},
				},
			],
		});
	}, []);

	useEffect(() => {
		if (!settings.render) setSettings({ ...settings, render: true });
	}, [settings.render]);

	if (!settings.render) return null;
	if (error) return <div>{"error"}</div>;
	if (!data) return null;

	const newData = data.featuredSearches.reduce((acco, e, i) => {
		let element = { id: i + 1, featured: e };
		if (i % 5 == 0) acco.push([element]);
		else if (i % 5 == 1 || i % 5 == 3) acco.push([element]);
		else if (i % 5 == 2 || i % 5 == 4) {
			let last = acco.pop();
			last.push(element);
			acco.push(last);
		}
		return acco;
	}, []);

	const banners = loading ? [...Array(3)] : newData;

	if (!banners.length) return null

	return (
		<>
			<h2 className="home-section-title ta-center heading heading-2 high">
				<span className="section-title">{"Búsquedas Recomendadas"}</span>
			</h2>
			<div className="featured-search">
				<Carousel {...settings}>
					{banners.map((e, i) => (
						<div className={"featured-search-item"} key={"featuredSearch" + i}>
							<Row gutter={[0, theme.spacing.mdSpacing]}>
								{e.map(item => (
									<Col
										span={24}
										flex={"1 0"}
										key={"FeaturedSearchItem" + item.id}>
										<FeaturedSearchItem item={item} loading={loading} />
									</Col>
								))}
							</Row>
						</div>
					))}
				</Carousel>

			</div>
		</>
	);
};
