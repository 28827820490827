import "./styles.less";

import React, { useEffect, useState, useContext, useRef } from "react";

import { ImagenOptimizada } from "../../../Components/Image/web";
import { useBannerFeaturedAgents } from "./BannerFeaturedAgents.hook";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";


const BannerFeaturedAgents = ({type="inmo"}:{type:"inmo"|"dev"}) => {
	
	const { country_code } = useContext(ConfigStateContext);
	const { data } = useBannerFeaturedAgents(type);
	const screen = useBreakpoint();
	
	if(!data || data.length <=0) return null

	return <div className="featured-agents-wrapper">
		<h5> {type == "inmo" ? "INMOBILIARIAS": "DESARROLLADORES" } </h5>

		<FaCarousel>
			{data.map((agent, i) => 
				<a
					key={"featured_agent_" + i}
					target="_blank"
					href={ country_code === "UY" ? agent.inmoLink : agent.inmoPropsLink }
					title={agent.name}
					rel="nofollow"
				>
					<ImagenOptimizada src={agent.logo} alt={agent.name} style={{objectFit:"contain", height:"56px", maxWidth:"137px"}} />
				</a>
			)}
		</FaCarousel>

	</div>
};

export { BannerFeaturedAgents };


const FaCarousel = ({children, slidesToShow=1}) => {
	const [currentIndex, setCurrentIndex] = useState(0)
	const [length, setLength] = useState(children.length)
	const rail = useRef<HTMLDivElement>(null)
	const [xTranslation, setXtranslation] = useState(0)

	useEffect(() => {
		let lengthValue = Math.ceil(children.length)
		setLength(lengthValue)
	}, [children])

	const next = () => {
		if (currentIndex < (length - 1)) {
			setCurrentIndex(prevState => prevState + 1)
			setXtranslation(prevState => prevState + rail.current.children[currentIndex].clientWidth )
		}
	}
	
	const prev = () => {
		if (currentIndex > 0) {
			setCurrentIndex(prevState => prevState - 1)
			setXtranslation(prevState => prevState - rail.current.children[currentIndex-1].clientWidth )
		}
	}

	return <>
		<div className="carousel-container">
			<div className="carousel-wrapper">
				{currentIndex > 0 &&
					<button onClick={prev} className="left-arrow">
						&#10094;
					</button>
				}
				<div className="carousel-content-wrapper">
					<div className="carousel-content" style={{ transform: `translateX(-${xTranslation}px)` }} ref={rail}>
						{children.map((c,i) => <div key={i}>{c}</div>)}
					</div>
				</div>
				{currentIndex < (length - 1) &&
					<button onClick={next} className="right-arrow">
						&#10095;
					</button>
				}
			</div>
		</div>
	</>
}
