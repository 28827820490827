import { Col, Row, Typography } from "antd";
import "./styles.less";

export const AppBanner = () => {
    return (
        <div className="banner-app">
            <img src="https://cdn2.infocasas.com.uy/web/62e1dbd6d5e4f_banner-app.png" alt="Banner APP" className="principal-image" />
            <Row gutter={[16, 16]} className="buttons-container">
                <Col xs={24} sm={24} md={12}>
                    <Typography.Link
                        title="App Store"
                        href="https://itunes.apple.com/uy/app/casas-en-el-este/id1596161064?mt=8"
                        target="_blank"
                        className="button-banner"
                        rel="nofollow"
                    >
                        <span className="app-text">DISPONIBLE EN</span>
                        <img src="https://cdn2.infocasas.com.uy/web/62e1dbd6828ba_logo_appstore.png" alt="App Store" />
                    </Typography.Link>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Typography.Link
                        title="Playstore"
                        href="https://play.google.com/store/apps/details?id=uy.com.casaseneleste.infoapp"
                        target="_blank"
                        className="button-banner"
                        rel="nofollow"
                    >
                        <span className="app-text">DISPONIBLE EN</span>
                        <img src="https://cdn2.infocasas.com.uy/web/62e1dbd6862db_logo_playstore.png" alt="Playstore" />
                    </Typography.Link>
                </Col>
            </Row>
        </div>
    )
}