

export function withTranslation(PageComponent) {
	const withTranslation = ({ ...pageProps }) => {
		return (
				<PageComponent {...pageProps} />
		);
	};

	if (PageComponent.getInitialProps) {
		withTranslation.getInitialProps = async ctx => {
			let pageProps = {};
			if (PageComponent.getInitialProps) pageProps = await PageComponent.getInitialProps(ctx);
			return { ...pageProps };
		};
	}

	return withTranslation;
}
