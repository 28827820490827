
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

const INMO_QUERY = gql`
	query PremiumRealAgents($type: String) {
		realEstateAgents(premium_agents: true, type: $type ) {
			id
			logo
			name
			profile_url
			inmoLink
			inmoPropsLink
		}
	}
`;

const useBannerFeaturedAgents = (type:"inmo"|"dev" = "inmo") => {
	const { data, loading, error } = useQuery(INMO_QUERY, { 
		ssr: true,
		variables:{type: type=='dev' ? 'desarrollador' : 'inmobiliaria'}
	});

	return {
		loading: loading,
		data: data?.realEstateAgents,
		error: error,
	};
};

export { useBannerFeaturedAgents };
