import Icon from "@ant-design/icons";

const RightArrowSvg = () => (
	<svg
		width="0.6em"
		height="1em"
		viewBox="0 0 11 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.147 8.948L0 1.893L1.868 0L10.936 8.951L1.867 17.878L0.000999451 15.982L7.147 8.948Z"
			fill="currentColor"
		/>
	</svg>
);

export const RightArrowIcon = props => <Icon component={RightArrowSvg} {...props} />;
